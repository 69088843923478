<template>
    <div v-if="lists.length > 0">
        <h1 class="mt-7 mb-3" style="text-align: center; color: #4CAF50;"> Trending List Topics </h1>
        <TopicPanel v-for="(l, index) in lists" :index="index" :key="index" :list="l" />
        <h1 class="mt-10 mb-3" style="text-align: center; color: #4CAF50;"> Individual Lists </h1>
    </div>
</template>
<script>
import api from '/src/utils/api';

export default {
    components: {
        TopicPanel: () => import("./TopicPanel"),
    },
    data() {
        return {
            lists: '',
        };
    },
    computed:{
    },
    mounted() {
        this.fetchLists();
    },
    methods: {
        fetchLists() {
            api.getCall('get-list-topics').then(lists => {
                // console.log("Recent::lists", lists);
                this.lists = lists;
            });
        },
    },
};
</script>

<style scoped>
</style>